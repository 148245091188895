import styled from "@emotion/styled";
import gsap, { Elastic, Power4 } from "gsap";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { forwardRef } from "react";
import { Image } from "react-datocms";
import SplitType from "split-type";

import useGsapSelector from "../../hooks/useGsapSelector";
import theme from "../../theme";
import mq from "../../utils/mediaQuery";
import ContentWrapper from "../ContentWrapper";
import Heading from "../Heading";
import Link from "../Link";
import PillButton from "../PillButton";
import ProductWithIcon from "../ProductWithIcon";
import RotatingButton from "../RotatingButton";

const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${theme.spacing("xxl")};
  margin-bottom: ${theme.spacing("xl")};
  gap: ${theme.spacing("xl")};

  ${mq("3")} {
    margin-top: ${theme.spacing("xxxl")};
    margin-bottom: ${theme.spacing("xxxl")};
    gap: ${theme.spacing("xxl")};
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

const TitleContentWrapper = styled(ContentWrapper)`
  box-sizing: border-box;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
`;

const Carousel = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  flex: 1 0 auto;
  ${mq("3")} {
    align-items: stretch;
  }
`;

const CarouselWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding-bottom: ${theme.spacing("s")};
  ${mq("3")} {
    min-height: 100vh;
    flex-direction: row;
    align-items: center;
    padding-bottom: 0;
  }
`;

const CaseDescription = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: ${theme.spacing("l")} ${theme.spacing("s")} 0 ${theme.spacing("l")};
  gap: ${theme.spacing("m")};
  ${mq("3")} {
    flex: 1 1 50%;
    padding: ${theme.spacing("xxxl")};
    gap: ${theme.spacing("xxl")};
  }
`;

const CaseContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${theme.spacing("s")};
`;

const CaseHeading = styled.h2`
  font-family: ${theme.family("secondary")};
  line-height: ${theme.lh("HeadingLarge")};
  font-size: ${theme.fs("HeadingLarge")};
  font-weight: ${theme.weight("secondary.regular")};
  max-width: 390px;
  margin: 0;

  ${mq("3")} {
    line-height: ${theme.lh("desktop.HeadingLarge")};
    font-size: ${theme.fs("desktop.HeadingLarge")};
  }
`;

const CaseSubHeading = styled.span`
  font-family: ${theme.family("primary")};
  line-height: ${theme.lh("h5")};
  font-size: ${theme.fs("h5")};
  font-weight: ${theme.weight("primary.bold")};
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

const ImageWrapper = styled.div`
  position: relative;
  align-self: stretch;
  flex: 1 1 50%;
  min-height: 100%;
  min-height: 400px;
`;

const ImageSlider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1;
`;

const CtaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CaseCtaWrapper = styled.div`
  align-self: flex-start;
`;

const CarouselIndicator = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing("xxs")};
  padding-right: ${theme.spacing("s")};
  padding-left: ${theme.spacing("s")};
  min-height: 400px;
  ${mq("3")} {
    padding-left: 0;
  }
`;

const CarouselIndicatorItem = styled.a`
  position: relative;
  width: 4px;
  height: 32px;
  cursor: pointer;
  background-color: ${({ active }) =>
    active ? theme.color("primary") : theme.color("dark.lighter")};
`;

const CaseCarousel = forwardRef(({ title, cases, cta }, ref) => {
  const [activeCase, setActiveCase] = useState(0);
  const [clickable, setClickable] = useState(true);
  const [q, contentRef] = useGsapSelector();
  const imageSliderRef = useRef();
  const titleRef = useRef();
  const ctaRef = useRef();
  const subHeadingRef = useRef();

  const tl = useRef();
  const clickTl = useRef();
  const titleSplit = useRef();

  useEffect(() => {
    titleSplit.current = SplitType.create(titleRef.current, { types: "lines" });
  }, []);

  useEffect(() => {
    tl.current = gsap.timeline({
      onComplete: () => {
        titleSplit.current.revert();
        setActiveCase(activeCase >= cases.length - 1 ? 0 : activeCase + 1);
        titleSplit.current.split();
      },
    });
    //intro
    tl.current.to(imageSliderRef.current, {
      transformOrigin: "right",
      duration: 1,
      scaleX: 0,
      ease: Power4.easeOut,
    });
    tl.current.set(
      contentRef.current,
      {
        opacity: 1,
      },
      "<"
    );
    tl.current.from(
      titleSplit.current.lines,
      {
        opacity: 0,
        y: 20,
        duration: 1,
        stagger: { amount: 0.35 },
      },
      "<"
    );
    if (subHeadingRef.current) {
      tl.current.from(
        subHeadingRef.current,
        {
          opacity: 0,
          duration: 0.5,
        },
        "-=0.5"
      );
    }
    tl.current.to(ctaRef.current, {
      scale: 1,
      opacity: 1,
      duration: 1,
      ease: Elastic.easeOut.config(2, 1),
    });
    tl.current.add(() => setClickable(true));

    //outro
    tl.current.to(
      imageSliderRef.current,
      {
        transformOrigin: "left",
        scaleX: 1,
        duration: 1,
        ease: Power4.easeInOut,
      },
      "+=6"
    );
    tl.current.to(
      contentRef.current,
      {
        opacity: 0,
      },
      "<"
    );
    tl.current.to(
      ctaRef.current,
      {
        scale: 0,
        opacity: 0,
        duration: 1,
        ease: Elastic.easeIn.config(2, 1),
      },
      "<"
    );
    return () => {
      tl.current.kill();
    };
  }, [q, cases, activeCase, contentRef]);

  const animateOut = (index) => {
    if (!clickable) {
      return;
    }

    setClickable(false);

    clickTl.current = gsap.timeline({
      onComplete: () => {
        setActiveCase(index);
        clickTl.current.kill();
      },
    });
    clickTl.current.to(imageSliderRef.current, {
      transformOrigin: "left",
      scaleX: 1,
      duration: 1,
      ease: Power4.easeInOut,
    });
    clickTl.current.to(
      contentRef.current,
      {
        opacity: 0,
      },
      "<"
    );
    clickTl.current.to(
      ctaRef.current,
      {
        scale: 0,
        opacity: 0,
        duration: 1,
        ease: Elastic.easeIn.config(2, 1),
      },
      "<"
    );
  };

  return (
    <Wrapper>
      <TitleWrapper>
        <TitleContentWrapper columns={8}>
          <Heading size={2}>{title}</Heading>
        </TitleContentWrapper>
      </TitleWrapper>
      <Carousel ref={ref}>
        <CarouselWrapper>
          <ImageWrapper>
            <ImageSlider ref={imageSliderRef} />
            <Image
              layout="fill"
              objectFit="cover"
              objectPosition="50% 50%"
              data={cases[activeCase].image.data}
            />
          </ImageWrapper>
          <CaseDescription>
            <CaseContent ref={contentRef}>
              <CaseHeading
                ref={titleRef}
              >{`${cases[activeCase].title}`}</CaseHeading>

              {cases[activeCase]?.products.length > 0 &&
                cases[activeCase]?.productGroup && (
                  <CaseSubHeading ref={subHeadingRef}>
                    <ProductWithIcon product={cases[activeCase].products[0]} />{" "}
                    {`${cases[activeCase].productGroup}`}
                  </CaseSubHeading>
                )}
            </CaseContent>

            {cases[activeCase].slug && (
              <CaseCtaWrapper ref={ctaRef}>
                <Link to={`${cases[activeCase].slug}`}>
                  <RotatingButton arrowDirection="right" label={"Lees meer"} />
                </Link>
              </CaseCtaWrapper>
            )}
          </CaseDescription>
        </CarouselWrapper>
        <CarouselIndicator>
          {cases.map((caseItem, index) => (
            <CarouselIndicatorItem
              key={index}
              active={index === activeCase}
              onClick={() => animateOut(index)}
            />
          ))}
        </CarouselIndicator>
      </Carousel>

      {cta && (
        <CtaWrapper>
          <Link to={`/${cta.path}`}>
            <PillButton label={cta.label} />
          </Link>
        </CtaWrapper>
      )}
    </Wrapper>
  );
});

CaseCarousel.displayName = `CaseCarousel`;

CaseCarousel.propTypes = {
  title: PropTypes.string.isRequired,
  cases: PropTypes.array.isRequired,
  cta: PropTypes.object,
};

export default CaseCarousel;
